import { Container, Grid, Typography } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CheckList from "../components/CheckList";
import EnquiryForm from "../components/EnquiryForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  invoiceFinancePageHeroContainer: {
    backgroundColor: teal[50]
  },
  invoiceFinancePageTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const InvoiceFinancePage = () => {
  const classes = useStyles();
  const title = "Invoice Finance Quote";
  const description =
    "Using invoice finance will improve your cash flow and help your business grow. Our expert advisers can find lenders suitable to your business.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.invoiceFinancePageHeroContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Typography
                className={classes.invoiceFinancePageTitle}
                variant="h1"
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <CheckList
                points={[
                  "Up to 90% of the value of your invoices",
                  "Start-ups and exporters",
                  "No fees"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <EnquiryForm type="INVOICE_FINANCE" />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default InvoiceFinancePage;
